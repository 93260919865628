import service from '@/utils/request';

// @Tags RmReportBrowse
// @Summary 创建RmReportBrowse
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmReportBrowse true "创建RmReportBrowse"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmReportBrowse/createRmReportBrowse [post]
export var createRmReportBrowse = function createRmReportBrowse(data) {
  return service({
    url: "/rmReportBrowse/createRmReportBrowse",
    method: 'post',
    data: data
  });
};

// @Tags RmReportBrowse
// @Summary 删除RmReportBrowse
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmReportBrowse true "删除RmReportBrowse"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmReportBrowse/deleteRmReportBrowse [delete]
export var deleteRmReportBrowse = function deleteRmReportBrowse(data) {
  return service({
    url: "/rmReportBrowse/deleteRmReportBrowse",
    method: 'delete',
    data: data
  });
};

// @Tags RmReportBrowse
// @Summary 删除RmReportBrowse
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmReportBrowse"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmReportBrowse/deleteRmReportBrowse [delete]
export var deleteRmReportBrowseByIds = function deleteRmReportBrowseByIds(data) {
  return service({
    url: "/rmReportBrowse/deleteRmReportBrowseByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmReportBrowse
// @Summary 更新RmReportBrowse
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmReportBrowse true "更新RmReportBrowse"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmReportBrowse/updateRmReportBrowse [put]
export var updateRmReportBrowse = function updateRmReportBrowse(data) {
  return service({
    url: "/rmReportBrowse/updateRmReportBrowse",
    method: 'put',
    data: data
  });
};

// @Tags RmReportBrowse
// @Summary 用id查询RmReportBrowse
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmReportBrowse true "用id查询RmReportBrowse"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmReportBrowse/findRmReportBrowse [get]
export var findRmReportBrowse = function findRmReportBrowse(params) {
  return service({
    url: "/rmReportBrowse/findRmReportBrowse",
    method: 'get',
    params: params
  });
};

// @Tags RmReportBrowse
// @Summary 分页获取RmReportBrowse列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmReportBrowse列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmReportBrowse/getRmReportBrowseList [get]
export var getRmReportBrowseList = function getRmReportBrowseList(params) {
  return service({
    url: "/rmReportBrowse/getRmReportBrowseList",
    method: 'get',
    params: params
  });
};
export var getRmReportBrowseInfoByUserId = function getRmReportBrowseInfoByUserId(params) {
  return service({
    url: "/rmReportBrowse/getRmReportBrowseInfoByUserId",
    method: 'get',
    params: params
  });
};

// 按条件查询RmReportBrowse
export var getRmRptBrwListByParams = function getRmRptBrwListByParams(params) {
  return service({
    url: "/rmReportBrowse/getRmRptBrwListByParams",
    method: 'post',
    params: params
  });
};